import React from "react";
import PageTemplate from "../components/page-template";
import Hero from "../components/hero";
import Contact from '../components/contact-form';

// import AboutUs from "../components/about-us";

const WhatWeDo = () => {
  return (
    <PageTemplate title="About Us | Innovate Web Solutions">
      <Hero
        size="small"
        color="primary"
        content={
          <div>
            <h1 className="title color-primary">Websites</h1>
            <p>
              At Innovate Web Solutions, we build custom websites for any
              purpose using ONLY the latest and greatest technologies. We carry
              out analysis and trends in current web design in the UK ensuring
              your site stays up to date!
            </p>
          </div>
        }
      />

      <section className="section">
        <div className="container">

        <h2 className="subtitle">What is the purpose for your website?</h2>
        <p>Websites explained: Before you start, you might want to consider the purpose of your website. Ask yourself what are you trying to achive?</p>
        <p></p>

          <div className="p-section">
            <h3 className="subtitle">Types of Websites</h3>
            <p>
              Depending on your purpose, websites types can vary. For example,
              if your a corperate business, chances are you just need a site for
              information purposes which holds all of your terms and conditions
              and also a contact page. If your an online seller, your going to
              need a online store where you can showcase your latest and
              greatest items. Below we have outlined types of websites and what
              they are best used for.{" "}
            </p>
          </div>

          <div className="p-section">
            <h4 className="subtitle">Corporate Websites</h4>
            <p>
              A corperate website if often considered as the "Face of a
              business". Its important to make a good first impression to retain
              your visiters attention. With a modern, up to date corperate
              website you can do just that! These types of websites often
              display information about the company, the services they provide,
              links to there social media and how to get into contact wit them.
            </p>
          </div>
          <div className="p-section">
            <h4 className="subtitle">E Commerce Websites</h4>
            <p>
              Most commonly used for selling goods, services, subscriptions or
              memberships online. E Commerce websites are great for online sellers
              as they can optimise their sales with a larger audience
              when compared to a phyiscal shop in the local town.
              There are many companies promoting there e Commerce platforms
              where you can build a DIY store. These types of platform dont fit
              everyones needs as you lack the ability to truely make your e
              comerce website bespoke and instead are forced to use pre made
              themes and checkout processes.{" "}
            </p>
          </div>
          <div className="p-section">
            <h4 className="subtitle">Landing Page</h4>
            <p>
              A Landing page is a single page website. Great for the likes of a
              portfolio, Online advertisment for Apps or product or even to sum
              up your business in a few short paragraphs. Landing pages are
              usually a great choice for statups as they dont have a lot of
              content they need to display on there site. Landing pages are also
              a great fit for anyone with a tight budget who just needs an
              online presence.
            </p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h2>Our website design process</h2>
          <p>
            How we tailor make ALL of our websites from quote to after sales
            support
          </p>
          gather requirements provide a quote / estimate plan / design / signoff
          build / test / review sign off / push live / aftercare
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h2>How much does a website cost in (year)</h2>
          <p>Small paragraph, call to action for website calculator</p>
        </div>
      </section>


        <Contact />

    </PageTemplate>
  );
};

export default WhatWeDo;
